<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline :title="'ICAR订单'"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <!-- 表格数据搜索 -->
      <template #header="params">
        <div class="search_box">
          <el-tabs v-model="selectTab" @tab-click="pageHandler(params, 1)">
            <el-tab-pane
              v-for="(item, index) in tabList"
              :key="index"
              :label="item.stageName+'('+item.count+')'"
              :name="item.stage+''"
            ></el-tab-pane>
          </el-tabs>
          <div style="display: flex;justify-content: end">
            <el-button type="text" @click="operBtnHandle">{{operBtnText}}<i :class="operBtnClas"></i></el-button>
          </div>
          <el-form :inline="true" :model="searchData" label-position="left">
            <el-form-item>
              <el-input v-model="searchData.searchKeyword" placeholder="姓名/手机/证件号" size="small" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="searchData.vin" placeholder="VIN" size="small" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.orderStatus" placeholder="订单状态" size="small" clearable>
                <el-option label="待交车" value="1"></el-option>
                <el-option label="已交车" value="2"></el-option>
                <el-option label="已退订" value="4"></el-option>
                <el-option label="已退货" value="5"></el-option>
                <el-option label="退订审批中" value="6"></el-option>
                <el-option label="退货审批中" value="7"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-input v-model="searchData.saleConsultantName" placeholder="所属经销商" size="small" clearable></el-input>
            </el-form-item>


            <el-form-item>
              <el-input v-model="searchData.saleConsultantName" placeholder="销售顾问"  size="small" clearable></el-input>
            </el-form-item>

            <el-form-item>
              <el-select v-model="searchData.offlineChargebacks" placeholder="是否线下退单"  size="small" clearable>
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-select v-model="searchData.orderType" placeholder="订单类型" size="small" clearable>
                <el-option label="手动下订" value="0"></el-option>
                <el-option label="商城订单" value="1"></el-option>
              </el-select>
            </el-form-item>

            <div v-if="operBtnFlag">
              <el-form-item label=下单时间 label-width="80px" size="small" clearable>
                <el-date-picker
                  v-model="searchData.depositStartTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item label="退订审核通过时间" label-width="130px" size="small" clearable>
                <el-date-picker
                  v-model="searchData.cancelDepositStartTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item label="退货审核通过时间" label-width="130px" size="small" clearable>
                <el-date-picker
                  v-model="searchData.returnCarAuditRejectStartTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </el-form>
          <el-row :gutter="10">
            <el-col :span="4" :offset="20">
              <el-button @click="resetSearchData">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>

      <!-- 表格顶部操作 -->
      <template #navBar>
        <div class="table_top_box">
          <div>共{{ customerTotal }}条线索</div>
          <el-button type="text" icon="el-icon-refresh-left" @click="updateUserFn">更新数据</el-button>
        </div>
      </template>

      <!-- 表格本体 -->
      <el-table-column label="操作" fixed="right" prop="" >
        <template slot-scope="scope">
          <el-link type="primary" @click="openUserInfoFn(scope.row)" style="font-size: 13px;">详情</el-link>
        </template>
      </el-table-column>

      <span v-for="(item, index) in columnData" :key="index">
        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-if="item.prop == 'orderType'">
          <template slot-scope="scope">
            {{ scope.row.orderType | orderTypeFormatter}}
          </template>
        </el-table-column>
        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'gender'">
          <template slot-scope="scope">
            {{ scope.row.gender | genderFormatter}}
          </template>
        </el-table-column>
      <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'orderStatus'">
          <template slot-scope="scope">
            {{ scope.row.orderStatus | orderStatusFormatter}}
          </template>
        </el-table-column>
        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'carModelName'" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.carModelName }}
          </template>
        </el-table-column>
        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'completedCarModelName'" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.completedCarModelName }}
          </template>
        </el-table-column>

        <el-table-column v-else :label="item.label" :prop="item.prop" :min-width="item.width">
          <template slot-scope="scope">
            <div v-if="scope.row[item.prop] || scope.row[item.prop] === 0">{{ scope.row[item.prop] }}</div>
            <div v-else>--</div>
          </template>
        </el-table-column>

      </span>
    </my-table>

    <el-drawer :visible.sync="userInfoDrawer" :with-header="false" size="70%">
      <el-container class="container_box">
        <!-- 头 -->
        <el-header class="container_box_head" height="auto">
          <div style="font-size: 16px;font-weight: 500;line-height: 40px;">基础信息</div>
          <el-row>
            <el-col :span="8">
              <span class="label">订单编号：</span>
              <span class="value">{{orderDetailData.orderCode }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">客户名称：</span>
              <span class="value">{{emptyToLine(orderDetailData.customerName)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">手机号：</span>
              <span class="value">{{emptyToLine(orderDetailData.phone)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">证件号：</span>
              <span class="value">{{emptyToLine(orderDetailData.certificateNumber)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">性别：</span>
              <span class="value">{{orderDetailData.gender | genderFormatter}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">出生日期：</span>
              <span class="value">{{emptyToLine(orderDetailData.birthday)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">省市区：</span>
              <span class="value">{{
                    emptyToLine(orderDetailData.province)+ "/" +
                    emptyToLine(orderDetailData.city)+ "/" +
                    emptyToLine(orderDetailData.district)
                }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">详细地址：</span>
              <span class="value">{{emptyToLine(orderDetailData.address)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">婚姻状况：</span>
              <span class="value">{{orderDetailData.maritalStatus | maritalStatusFormatter}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">学历：</span>
              <span class="value">{{orderDetailData.educationLevel | educationLevelFormatter}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">收入：</span>
              <span class="value">{{orderDetailData.incomeLevel | incomeLevelFormatter}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">行业：</span>
              <span class="value">{{orderDetailData.businessType | businessTypeFormatter}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">兴趣爱好：</span>
              <span class="value">{{emptyToLine(orderDetailData.interest)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">首触渠道：</span>
              <span class="value">{{emptyToLine(orderDetailData.channelName)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">销售顾问：</span>
              <span class="value">{{emptyToLine(orderDetailData.saleConsultantName)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">所属经销商：</span>
              <span class="value">{{emptyToLine(orderDetailData.dealerName)}}</span>
            </el-col>
          </el-row>
        </el-header>
        <el-container>
          <el-main class="container_box_main">
            <el-tabs class="container_box_main_tabs" v-model="selectMainTab" type="card" @tab-click="maintabHandleClick">
              <el-tab-pane label="下单信息" name="1"></el-tab-pane>
              <el-tab-pane label="成交信息" name="2"></el-tab-pane>
              <el-tab-pane label="操作记录" name="3"></el-tab-pane>
            </el-tabs>

            <div class="container_box_main_content" v-if="selectMainTab==1">
              <div v-if="orderDetailData">
                <el-row>
                  <el-col :span="8">
                    <span class="label">下订时间：</span>
                    <span class="value">{{orderDetailData.depositTime }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="label">订单类型：</span>
                    <span class="value">{{orderDetailData.orderType | orderTypeFormatter}}</span>
                  </el-col>
                  <el-col :span="8" style="display: flex;align-items: center;">
                    <span class="label">商城订单状态：</span>
                    <span class="value">{{orderDetailData.mallOrderStatus}}</span>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="8">
                    <span class="label">订金：</span>
                    <span class="value">{{orderDetailData.depositAmount }} 元</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="label">订金类型：</span>
                    <span class="value">{{orderDetailData.depositType | depositTypeFormatter}}</span>
                  </el-col>
                  <el-col :span="8" style="display: flex;align-items: center;">
                    <span class="label">下订证件号：</span>
                    <span class="value">{{emptyToLine(orderDetailData.depositCertificateNumber)}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <span class="label">下订证件类型：</span>
                    <span class="value">{{ orderDetailData.depositCertificateType |certificateTypeFormatter }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="label">计划交车时间：</span>
                    <span class="value">{{emptyToLine(orderDetailData.planDeliveryTime)}}</span>
                  </el-col>
                  <el-col :span="8" style="display: flex;align-items: center;">
                    <span class="label">下订车型：</span>
                    <span class="value">{{emptyToLine(orderDetailData.carModelName)}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <span class="label">颜色：</span>
                    <span class="value">{{orderDetailData.carColorName }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="label">付款方式：</span>
                    <span class="value">{{orderDetailData.paymentType | paymentTypeFormatter}}</span>
                  </el-col>
                  <el-col :span="8" style="display: flex;align-items: center;">
                    <span class="label">协议方式：</span>
                    <span class="value">{{orderDetailData.protocolType}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <span class="label">接待录音：</span>
                    <span class="value">{{ emptyToLine()}}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="label">是否线下退单：</span>
                    <span class="value">{{orderDetailData.offlineChargebacks}}</span>
                  </el-col>
                  <el-col :span="8" style="display: flex;align-items: center;">
                    <span class="label">备注：</span>
                    <span class="value">{{emptyToLine(orderDetailData.remark)}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <span class="label">附件：</span>
                    <span class="value">{{orderDetailData.attach }}</span>
                  </el-col>
                </el-row>
              </div>
              <el-empty v-else :image-size="200"></el-empty>
            </div>

            <div class="container_box_main_content" v-if="selectMainTab==2">
              <div v-if="orderDetailData.invoiceResult">
                <el-row>
                  <el-col :span="8">
                    <span class="label">成交时间：</span>
                    <span class="value">{{emptyToLine(orderDetailData.invoiceResult.completedTime) }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="label">发票代码：</span>
                    <span class="value">{{emptyToLine(orderDetailData.invoiceResult.invoiceCode)}}</span>
                  </el-col>
                  <el-col :span="8" style="display: flex;align-items: center;">
                    <span class="label">发票号码：</span>
                    <span class="value">{{emptyToLine(orderDetailData.invoiceResult.invoiceNum)}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <span class="label">开票日期：</span>
                    <span class="value">{{emptyToLine(orderDetailData.invoiceResult.invoiceDate) }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="label">购方名称：</span>
                    <span class="value">{{emptyToLine(orderDetailData.invoiceResult.buyerName)}}</span>
                  </el-col>
                  <el-col :span="8" style="display: flex;align-items: center;">
                    <span class="label">证件类型：</span>
                    <span class="value">{{orderDetailData.invoiceResult.certificateType | certificateTypeFormatter}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <span class="label">证件号码：</span>
                    <span class="value">{{orderDetailData.invoiceResult.certificateNum }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="label">价税合计：</span>
                    <span class="value">{{emptyToLine(orderDetailData.invoiceResult.priceTaxAmount)}}元</span>
                  </el-col>
                  <el-col :span="8" style="display: flex;align-items: center;">
                    <span class="label">车架号：</span>
                    <span class="value">{{emptyToLine(orderDetailData.invoiceResult.vin)}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <span class="label">购车用途：</span>
                    <span class="value">{{orderDetailData.invoiceResult.purchasePurposeDesc }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="label">车辆性质：</span>
                    <span class="value">{{emptyToLine(orderDetailData.vehiclePropertyDesc)}}</span>
                  </el-col>
                  <el-col :span="8" style="display: flex;align-items: center;">
                    <span class="label">延迟成交理由：</span>
                    <span class="value">{{emptyToLine(orderDetailData.noDeliveryCarReason)}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <span class="label">发票：</span>
                    <span class="value"><el-image :src="orderDetailData.invoiceResult.invoiceUrl" alt="" style="width: 150px;height: 150px;"></el-image></span>
                  </el-col>
                </el-row>
              </div>
              <el-empty v-else :image-size="200"></el-empty>
            </div>

            <div class="client_itinerary" v-if="selectMainTab==3" v-loading="false">
              <span v-if="Object.keys(carSaleOrderLoggerResult).length>0">

                <el-timeline >
                    <el-timeline-item :timestamp="item.completeTime" placement="top" v-for="(item, key) in carSaleOrderLoggerResult" :key="key">
                      <el-card>
                        <div v-if="item.orderSource" style="color: #595959;font-size: 14px;line-height: 30px">下订来源: {{item.orderSource}}</div>
                        <div v-if="item.saleConsultantName" style="color: #595959;font-size: 14px;line-height: 30px"> 销售顾问 : {{ item.saleConsultantName }}</div>
                        <div v-if="item.remark" style="color: #595959;font-size: 14px;line-height: 30px"> 备注 : {{ item.remark }}</div>
                      </el-card>
                    </el-timeline-item>
                </el-timeline>
              </span>
              <el-empty v-if="Object.keys(carSaleOrderLoggerResult).length<1" :image-size="200"></el-empty>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable */
import Template from "@/views/dashboard/template.vue";
import {
  emptyToLine,
} from "@/utils/common";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Template },
  filters:{
    orderTypeFormatter(orderType){
      switch (orderType){
        case 0:
          return '手动下订'
        case 1:
          return '商城订单'
      }
      return '--'
    },
    genderFormatter(gender){
      switch (gender){
        case 1:
          return '男'
        case 2:
          return '女'
      }
      return '未知'
    },
    orderStatusFormatter(orderStatus){
      switch (orderStatus){
        case 1:
          return '待交车'
        case 2:
          return '交车'
        case 3:
          return '成交'
        case 4:
          return '已退订'
        case 5:
          return '已退货'
      }
      return '--'
    },
    maritalStatusFormatter(maritalStatus){
      switch (maritalStatus){
        case 10:
          return '未婚'
        case 20:
          return '已婚'
        case 30:
          return '丧偶'
        case 40:
          return '离婚'
        case 90:
          return '未说明婚姻状况'
      }
      return '--'
    },
    educationLevelFormatter(educationLevel){
      switch (educationLevel){
        case 11:
          return '硕士'
        case 12:
          return '博士'
        case 13:
          return '博士后'
        case 20:
          return '本科'
        case 30:
          return '专科'
        case 40:
          return '中专/技校/职高'
        case 60:
          return '高中'
        case 70:
          return '初中'
        case 80:
          return '小学'
        case 90:
          return '其他'
      }
      return '--'
    },
    incomeLevelFormatter(incomeLevel){
      switch (incomeLevel){
        case 1:
          return '5万以下'
        case 2:
          return '5-10万'
        case 3:
          return '10-15万'
        case 4:
          return '15-20万'
        case 5:
          return '20-25万'
        case 6:
          return '25-30万'
        case 7:
          return '30-35万'
        case 8:
          return '35万以上'
        case 9:
          return '未知'
      }
      return '--'
    },
    businessTypeFormatter(businessType){
      switch (businessType) {
        case 1:
          return '农、林、牧、渔业'
        case 2:
          return '采矿业'
        case 3:
          return '制造业'
        case 4:
          return '电力、燃气及水的生产和工业业'
        case 5:
          return '建筑业'
        case 6:
          return '交通运输、仓储和邮政业'
        case 7:
          return '信息传输、计算机服务和软件业'
        case 8:
          return'批发和零售业'
        case 9:
          return '住宿和餐饮业'
        case 10:
          return '金融业'
        case 11:
          return '房地产业'
        case 12:
          return '租赁和商务服务业'
        case 13:
          return '科学研究、技术服务和地址勘察业'
        case 14:
          return '水利、环境和公共设施管理业'
        case 15:
          return '居民服务和其他服务业'
        case 16:
          return '教育'
        case 17:
          return '卫生、社会保障和社会福利业'
        case 18:
          return '文化、体育和娱乐业'
        case 19:
          return '公共管理和社会组织'
        case 20:
          return '国际组织'
        case 21:
          return '其他'
      }
    },
    depositTypeFormatter(depositType){
      switch (depositType){
        case 1:
          return '意向金'
        case 2:
          return '小订'
        case 3:
          return '大订'
      }
      return '--'
    },
    certificateTypeFormatter(certificateType){
      switch (certificateType){
        case 1:
          return '身份证'
        case 2:
          return '军官证'
        case 3:
          return '港澳台海外地区证件'
        case 4:
          return '企业统一社会信用代码'
      }
      return '--'
    },
    paymentTypeFormatter(paymentType){
      switch (paymentType){
        case 1:
          return '分期付款'
        case 2:
          return '一次付清'
        case 3:
          return '线上定金'
      }
      return '--'
    }
  },
  data() {
    //这里存放数据
    return {
      listData: [], //列表数据
      listLoading: false, //列表loading
      detailListLoading: false, //详情列表loading
      tabList: [],  //标签数据
      selectTab: "1", //标签已选数据
      selectMainTab: "1", //客户信息标签选择
      groupBtnData: "0", //旅程自定义tabs当前选中
      operBtnText: '展开',
      operBtnClas: 'el-icon-arrow-down el-icon--down',
      operBtnFlag : false,
      // 搜索数据
      searchData: {
        searchKeyword: "",
        vin: "",
        orderStatus: null,
        orderType: null,
        depositStartTime:  "",
        depositEndTime:  "",
        cancelDepositStartTime:  "",
        cancelDepositEndTime:  "",
        returnCarAuditRejectStartTime:  "",
        returnCarAuditRejectEndTime:  "",
        offlineChargebacks: null,
        saleConsultantName:  "",
        dealerName: ""
      },
      customerTotal: '',      //客户数量
      searchKey: "customerName", //下拉input所选Key
      // 表格column数据
      columnData: [
        { label: "订单类型", width: 100, prop: "orderType" },
        { label: "订单编号", width: 200, prop: "orderCode" },
        { label: "姓名", width: 200, prop: "customerName" },
        { label: "性别", width: 80, prop: "gender" },
        { label: "手机", width: 120, prop: "phone" },
        { label: "证件号", width: 180, prop: "certificateNumber" },
        { label: "订单状态", width: 180, prop: "orderStatus" },
        { label: "下订车型", width: 180, prop: "carModelName" },
        { label: "成交车型", width: 180, prop: "completedCarModelName" },
        { label: "VIN", width: 180, prop: "vin" },
        { label: "所属经销商", width: 250, prop: "dealerName" },
        // { label: "ERP", width: 180, prop: "" },
        { label: "销售顾问", width: 120, prop: "saleConsultantName" },
        { label: "是否线下退单", width: 150, prop: "offlineChargebacks" },
        { label: "下订日期", width: 160, prop: "depositTime" },
        { label: "退订审核通过日期", width: 160, prop: "cancelDepositTime" },
        { label: "退货审核通过日期", width: 160, prop: "returnCarAuditRejectTime" },
      ],
      userInfoDrawer: false, //用户信息抽屉状态
      carSaleOrderLoggerResult: [],
      orderDetailData: {},    //客户详情

      orderDialogVisible: false,    //订单详情弹窗状态
      orderTabSelect: "order",      //订单弹窗tab
      itineraryPageNum: 1,          //客户旅程当前页码
      itineraryPage: 0,             //客户旅程总页数
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    emptyToLine,
    // 页面列表翻页
    pageHandler(params, num) {
      this.getOrderList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        searchKeyword: "",
        vin: "",
        orderStatus: null,
        orderType: null,
        depositStartTime:  "",
        depositEndTime:  "",
        cancelDepositStartTime:  "",
        cancelDepositEndTime:  "",
        returnCarAuditRejectStartTime:  "",
        returnCarAuditRejectEndTime:  "",
        offlineChargebacks: null,
        saleConsultantName:  "",
        dealerName: ""
      }
    },
    operBtnHandle(){
      this.operBtnFlag = !this.operBtnFlag
      if(this.operBtnFlag){
        this.operBtnText = '收起'
        this.operBtnClas = 'el-icon-arrow-up el-icon--up'
      }else{
        this.operBtnText = '展开'
        this.operBtnClas = 'el-icon-arrow-down el-icon--down'
      }

    },

    // 点击客户展开详细信息
    openUserInfoFn(row) {
      this.selectTab = "1"; //标签已选数据
      this.selectMainTab = "1"; //客户信息标签选择
      this.groupBtnData = "0"; //旅程自定义tabs当前选中
      this.clueList = [];
      this.flowList = [];
      this.driveList = [];
      this.orderList = [];
      this.userInfoDrawer = true;
      this.itineraryPageNum = 1;          //客户旅程当前页码
      this.itineraryPage = 0;             //客户旅程总页数
      this.clientItineraryList = {};      //客户旅程数据
      // 获取客户客户详情
      this.getClientDetail(row.orderCode||"")
    },
    // 客户信息tab点击
    maintabHandleClick(e){
      this.selectMainTab = e.name;
      switch (e.name) {
        case "1":
          break;
        case "2":
          break;
        case "3":
          this.carSaleOrderLoggerResult = [];
          if(this.orderDetailData.orderLoggers){
            this.orderDetailData.orderLoggers.forEach(item => {
              var ext = JSON.parse(item.extJson)
              this.carSaleOrderLoggerResult.push({...ext})
            });
          }
          break;
        default:
          break;
      }
    },

    /**
     * 接口方法
     */

    updateUserFn(){
      this.$store.dispatch('newCarUser/getCarSaleOrderUpdate',{}).then(res => {
        this.$message.success('更新任务后台运行中，请稍后查看')
      })
    },
    // 获取用户列表
    getOrderList(params,pageNum,pageSize) {
      this.listLoading = true;
      var param  = {...this.searchData}

      if(this.searchData.depositStartTime){
        param.depositEndTime = this.searchData.depositStartTime[1]
        param.depositStartTime = this.searchData.depositStartTime[0]
      }

      if(this.searchData.cancelDepositStartTime){
        param.cancelDepositEndTime = this.searchData.cancelDepositStartTime[1]
        param.cancelDepositStartTime = this.searchData.cancelDepositStartTime[0]
      }

      if(this.searchData.returnCarAuditRejectStartTime){
        param.returnCarAuditRejectEndTime = this.searchData.returnCarAuditRejectStartTime[1]
        param.returnCarAuditRejectStartTime = this.searchData.returnCarAuditRejectStartTime[0]
      }

      this.$store.dispatch('newCarUser/getCarSaleOrderList',{
        ...param,
        stage: this.selectTab,
        pageNum,
        pageSize
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
          this.customerTotal = res.data.total;
        }
        this.listLoading = false;
      })
    },

    // 获取唯一线索详情
    getClientDetail(orderCode) {
      this.listLoading = true;
      this.$store.dispatch('newCarUser/getCarSaleOrderDetail',{
        orderCode:orderCode
      }).then(res => {
        if(res.code == 0){
          this.orderDetailData = res.data
        }
        this.listLoading = false;
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {}
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
::v-deep .el-drawer{
  min-width: 1100px !important;
}
.page_box {
  padding: 0 20px;
  .search_box {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
  }
  .table_box_custom {
    display: flex;
    align-items: center;
    cursor: pointer;
    .default_head {
      width: 60px;
      height: 60px;
      display: block;
      border-radius: 100%;
      margin-right: 10px;
    }
    .user_name {
      > div:nth-child(1) {
        color: #0083ff;
        font-size: 14px;
      }
    }
  }
  .table_top_box {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: -20px;
    box-sizing: border-box;
    .table_top_handle {
      margin-left: auto;
    }
  }
  .container_box {
    background: #f1f5f7;
    height: 100%;
    padding: 25px;
    box-sizing: border-box;
    .container_box_head {
      .label {
        color: #595959;
        line-height: 30px;
        font-size: 13px;
      }

      .value {
        color: #262626;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
      }
      background: #fff;
      margin-bottom: 25px;
      box-shadow: 4px 6px 20px 0 rgba(0,0,0,.02);
      padding: 20px;
      box-sizing: border-box;
      height: auto;
      .head_01{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .head_01_left{
          display: flex;
          align-items: center;
          .left_img{
            width: 70px;
            height: 70px;
            border-radius: 12px;
            margin-right: 20px;
            img{
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          .left_info{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 70px;
            .left_info_top{
              display: flex;
              align-items: center;
              >div{
                margin-right: 15px;
                font-size: 16px;
                font-weight: bold;
              }
              >i{
                margin-right: 10px;
                font-size: 18px;
                color: #d2d2d2;
                font-weight: bold;
              }
            }
            .left_info_down{
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              cursor: pointer;
              >div{
                margin-right: 15px;
                font-size: 12px;
                color: #74788d;
                text{
                  color: #409eff;
                }
              }
            }
          }
        }
      }
      .head_02{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 25px 0;
        .head_02_right,.head_02_left{
          width: 50%;
        }
        .head_02_left{
          border-right: 1px solid #e7e7e7;
          box-sizing: border-box;
          .left_item{
            display: flex;
            align-items: center;
            .left_people{
              display: flex;
              align-items: center;
              margin-right: 30px;
              .left_item_people{
                display: flex;
                align-items: center;
                font-size: 12px;
                border: 1px solid #dcdcdc;
                border-radius: 3px;
                padding: 4px 5px;
                img{
                  width: 18px;
                  height: 18px;
                  border-radius: 100%;
                  margin-right: 5px;
                }
              }
            }
            .left_item_btn{
              display: flex;
              align-items: center;
            }
          }
        }
        .head_02_right{
          padding-left: 25px;
          .right_item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >div{
              display: flex;
              align-items: center;
            }
            .tag_box{
              overflow: hidden;
              width: 300px;
              white-space: nowrap;
              .el-tag{
                margin-right: 5px;
              }
            }
            .tag_hint{
              color: #999;
              font-size: 14px;
            }
          }
        }
      }
      .head_03{
        display: flex;
        align-items: center;
        font-size: 12px;
        >div{
          margin-right: 20px;
        }
      }
    }
    .container_box_aside {
      background: #fff;
      margin-right: 20px;
      box-shadow: 4px 6px 20px 0 rgba(0,0,0,.02);
      padding: 20px;
      box-sizing: border-box;
      overflow: scroll;
      max-height: 644px;
      .container_box_aside_head{
        display: flex;
        margin-bottom: 10px;
        i{
          margin-top: 3px;
          margin-right: 5px;
        }
        .container_box_aside_title{
          font-size: 14px;
          font-weight: bold;
          color: #333;
          >div:nth-child(2){
            color: rgba(80, 93, 105, .6);
            font-size: 13px;
            margin-top: 5px;
          }
        }
      }
      .container_box_aside_item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 5px;
        box-sizing: border-box;
        font-size: 13px;
        >div:first-child{
          color: #74788d;
          width: 110px;
          flex-shrink: 0;
        }
        >div:last-child{
          flex: 1;
          color: #505d69;
          text-align: end;
        }
      }
    }
    .container_box_main {
      background: #fff;
      box-shadow: 4px 6px 20px 0 rgba(0,0,0,.02);
      padding: 0 !important;
      .container_box_main_tabs{
        margin-left: -1px;
      }
      .container_box_main_content{
        padding: 0 20px 20px 20px;
        box-sizing: border-box;

        .label {
          color: #595959;
          line-height: 30px;
          font-size: 13px;
        }

        .value {
          color: #262626;
          font-size: 13px;
          font-weight: 400;
          line-height: 30px;
        }

        .button_group{
          width: 100%;
          overflow: scroll;
          display: flex;
          align-items: center;
          >.button_group_item{
            margin: 0 !important;
            margin-right: -1px !important;
          }
          >.button_group_item:first-child{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          >.button_group_item:last-child{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
        .button_group::-webkit-scrollbar {
          display: none;
        }
      }
      // 客户旅程
      .client_itinerary{
        padding: 10px 50px;
        box-sizing: border-box;
        max-height: 520px;
        overflow-y: scroll;
        .client_itinerary_title{
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          >div:nth-child(2){
            font-size: 13px;
            color: #1883ff;
            border: 1px solid #1883ff;
            border-radius: 3px;
            box-sizing: border-box;
            padding: 0 5px;
          }
        }
        .client_itinerary_dot{
          margin-left: -19px;
          margin-top: 8px;
          background: #fff;
          padding: 10px 0;
          text-align: center;
          font-size: 12px;
          color: #74788d;
          white-space: pre-line;
          .client_itinerary_dot_icon{
            width: 40px;
            height: 40px;
            display: block;
            margin-bottom: 5px;
          }
          .client_itinerary_dot_circle{
            width: 11px;
            height: 11px;
            padding-top: 30px;
            position: absolute;
            top: -30px;
            left: -5px;
            background: #fff;
            .circle{
              width: 11px;
              height: 11px;
              border-radius: 100%;
              border: 1px solid #adb5bd;
              background: #f8f9fa;
              box-sizing: border-box;
            }
          }
        }
        .client_itinerary_dot_first{
          margin-top: 23px !important;
        }
        .client_itinerary_dot_html{
          white-space: pre-wrap !important;
          background: #f8f9fa;
          font-size: 13px;
          line-height: 20px;
          padding: 12px;
          box-sizing: border-box;
          margin: 10px 0;
          color: #505d69;
        }
        .client_itinerary_dot_operator{
          display: flex;
          align-items: center;
          font-size: 13px;
          img{
            width: 28px;
            height: 28px;
            display: block;
            border-radius: 100%;
            margin-right: 10px;
          }
          >div:nth-child(2){
            margin-right: 20px;
            color: #505d69;
          }
          >div:nth-child(3){
            color: #adb5bd;
          }
        }
      }
      // 客户旅程外另四个列表
      .table_model_box{
        padding: 0 15px;
        box-sizing: border-box;
      }
    }
  }
}
::v-deep .el-dialog__body{
  padding-top: 0 !important;
}
::v-deep .el-descriptions__header{
  margin: 10px 0px !important;
}
::v-deep .el-descriptions__title{
  font-size: 13px !important;
}
::v-deep .el-descriptions__body{
  padding: 0 20px !important;
}
//::v-deep .el-timeline-item__tail{
//  border-left: 1px solid #dcdee0 !important;
//  left: 0 !important;
//}
::v-deep .el-timeline .el-timeline-item:last-child .el-timeline-item__tail{
  display: initial !important;
}
::v-deep .el-timeline:last-child .el-timeline-item:last-child .el-timeline-item__tail{
  display: none !important;
}
::v-deep .el-timeline{
  margin-bottom: 30px !important;
}
::v-deep .el-timeline-item__timestamp{
  font-size: 16px !important;
  color: #505d69 !important;
  font-weight: bold;
  margin-bottom: 15px;
}
::v-deep .el-timeline-item__wrapper{
  padding-left: 50px !important;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav{
  border: none;
  border-radius: 0;
  box-sizing: border-box;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item{
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 3px solid transparent;
  box-sizing: border-box;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
  border-left: 1px solid #dcdee0;
  border-right: 1px solid #dcdee0;
  border-top: 3px solid #2278f5;
  box-sizing: border-box;
}
::v-deep .el-input-group__prepend {
  background-color: #fff !important;
}
::v-deep .el-table__header thead tr th:first-of-type {
  padding-left: 0;
}
.el-select {
  width: 110px !important;
}
</style>
